import {createI18n} from 'vue-i18n'
import {fr} from 'vuetify/locale'
import {en} from 'vuetify/locale'
import store from '@user-plugins/store/index.js'

const messages = {
    fr: {
        $vuetify: {
            ...fr,
        },
        '10': '10',
        '25': '25',
        '50': '50',
        '100': '100',
        "loading": "Loading...",
        "infiniteScroll": {
            "loadMore": "Load more"
        },
        'invalid_login': 'Identifiant invalide',
        "active_users": "utilisateurs actifs",
        "administrators": "administrateurs",
        "home": "Accueil",
        "the_payslips": "les bulletins de paie",
        "payslips": "bulletins de paie",
        "balance_of_any_account": "solde de tout compte",
        "loadings": "chargements",
        "welcome": "bienvenue",
        "users": "utilisateurs",
        "logged_system": "se connecte au système",
        "downloaded_file": "a téléchargé le fichier",
        "all": "tous",
        "assignment": "affectation",
        "affected": "affecté",
        "unaffected": "non affecté",
        "display": "affichage",
        "poster": "afficheur",
        "hidden": "caché",
        "type": "type",
        "payslip": "bulletin de paie",
        "modify": "Modifier",
        "notification": "notification",
        "notified": "notifié",
        "not_notified": "non notifié",
        "file_name": "nom de fichier",
        "downloader": "téléchargeur",
        "upload": "télécharger",
        "period": "période",
        "downloads": "téléchargements",
        "assigned_to": "assigné à",
        "visualization": "visualisation",
        "search": "recherche",
        "download": "Télecharger",
        "import": "Importer",
        "import_users": "Importer des utilisateurs",
        "import_payslips": "importer des bulletins de paie",
        "select_files": "sélectionner des fichiers",
        "please_select_the_files": "veuillez sélectionner les fichiers au format (pdf)",
        "the_users": "les utilisateurs",
        "cancel": "annuler",
        "delete": "Supprimer",
        "situation": "situation",
        "admin": "admin",
        "employee": "employé",
        "enabled": "activé",
        "disabled": "désactivé",
        "added": "ajouté",
        "full_name": "nom complet",
        "email": "e-mail",
        "username": "nom d'utilisateur",
        "password": "mot de passe",
        "sso": "SSO",
        "add_user": "ajouter un utilisateur",
        "add_admin": "ajouter un administrateur",
        "select_file": "sélectionner un fichier",
        "please_choose_an_excel_file": "veuillez choisir un fichier Excel (.xlsx) contenant un tableau avec les colonnes: ",
        "the_administrators": "les administrateurs",
        "edit_information": "modifier les informations",
        "change_password": "changer le mot de passe",
        "current_password": "mot de passe actuel",
        "new_password": "nouveau mot de passe",
        "confirm_the_new_password": "confirmer le nouveau mot de passe",
        "save_change": "Sauvegarder les modifications",
        "settings": "Paramètres",
        "sign_out": "Se déconnecter",
        "notif_add_success": "été ajouté avec succès.",
        "notif_edit_success": "été modifié avec succès.",
        "notif_delete_success": "été supprimé avec succès.",
        "notif_reset_password": "le mot de passe a été réinitialisé.",
        "notif_toggle_status": "Puis changez le statut avec succès",
        "title_confirm_delete": "Confirmer la suppression",
        "message_confirm_delete": "Etes-vous sûr que vous voulez supprimer",
        "edit_file": "Modifier le fichier",
        "login": "Se connecter",
        "remember_me": "Reste connecté",
        "message_2fa": "Nous avons envoyé un code de vérification à votre adresse e-mail",
        "check": "Vérifier",
        "message_didn_code": "Vous n'avez pas reçu le code ?",
        "resend": "Renvoyer",
        "Invalid_2fa_expired": "Le code 2FA est invalide ou a expiré. Veuillez générer un nouveau code et réessayer",
        "Invalid_2fa_code": "Code 2FA invalide. Veuillez saisir à nouveau votre code d'authentification à deux facteurs",
        "message_forgot_password": "Entrez votre email et nous vous enverrons des instructions pour réinitialiser votre mot de passe",
        "send_reset_link": "Envoyer le lien de réinitialisation",
        "back_to_login": "Retour à la page de login"
    },
    en: {
        $vuetify: {
            ...en,
        },
        '10': '10',
        '25': '25',
        '50': '50',
        '100': '100',
        "loading": "Loading...",
        "infiniteScroll": {
            "loadMore": "Load more"
        },
        'invalid_login': 'Identifiant invalide',
        "active_users": "active users",
        "administrators": "administrators",
        "home": "Home",
        "the_payslips": "The payslips",
        "payslips": "payslips",
        "balance_of_any_account": "balance of any account",
        "loadings": "loadings",
        "welcome": "welcome",
        "users": "users",
        "logged_system": "connects to the system",
        "downloaded_file": "downloaded the file",
        "all": "all",
        "assignment": "assignment",
        "affected": "affected",
        "unaffected": "unaffected",
        "display": "display",
        "poster": "show",
        "hidden": "hidden",
        "type": "type",
        "payslip": "payslip",
        "notification": "notification",
        "notified": "notified",
        "not_notified": "not notified",
        "file_name": "file name",
        "downloader": "downloader",
        "import": "Import",
        "import_users": "import users",
        "upload": "Upload",
        "period": "period",
        "downloads": "downloads",
        "assigned_to": "assigned to",
        "visualization": "visualization",
        "search": "search",
        "download": "download",
        "import_payslips": "import payslips",
        "select_files": "select files",
        "please_select_the_files": "please select the files in (pdf)",
        "the_users": "the users",
        "cancel": "cancel",
        "delete": "Delete",
        "modify": "Modify",
        "situation": "situation",
        "admin": "admin",
        "employee": "employee",
        "enabled": "enabled",
        "disabled": "disabled",
        "added": "added",
        "full_name": "full name",
        "email": "e-mail",
        "username": "username",
        "password": "password",
        "sso": "sso",
        "add_user": "add user",
        "add_admin": "add administrator",
        "select_file": "select file",
        "please_choose_an_excel_file": "please choose an excel file (.xlsx) that contains a table with the columns:",
        "the_administrators": "the administrators",
        "edit_information": "edit information",
        "change_password": "change password",
        "current_password": "current password",
        "new_password": "new password",
        "confirm_the_new_password": "confirm the new password",
        "save_change": "Save change",
        "settings": "Settings",
        "sign_out": "Sign out",
        "notif_add_success": "been added successfully.",
        "notif_edit_success": "been modified successfully.",
        "notif_delete_success": "been deleted successfully.",
        "notif_reset_password": "been reset password.",
        "notif_toggle_status": "Then change the status successfully",
        "title_confirm_delete": "Confirm the deletion",
        "message_confirm_delete": "Are you sure you want to delete",
        "edit_file": "Edit file",
        "login": "Login",
        "remember_me": "Remember me",
        "message_2fa": "We have sent a verification code to your email address",
        "check": "check",
        "message_didn_code": "Didn't receive the code ?",
        "resend": "Resend",
        "Invalid_2fa_expired": "The 2FA code is invalid or expired. Please generate a new code and try again",
        "Invalid_2fa_code": "Invalid 2FA code. Please re-enter your two-factor authentication code",
        "message_forgot_password": "Enter your email and we'll send you instructions to reset your password",
        "send_reset_link": "Send reset link",
        "back_to_login": "Back to login"
    },
}

const i18n = createI18n({
    legacy: false,
    locale: store.state.locale,
    fallbackLocale: store.state.locale,
    messages,
})

export default i18n;
