export default [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: '/',
        component: () => import('@user-layouts/Blank.vue'),
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('@user-views/auth/Login.vue'),
                meta: {
                    middleware: 'guest',
                },
            },
            {
                path: '/verify-2fa',
                name: 'verify-2fa',
                component: () => import('@user-views/auth/Verify2fa.vue'),
                meta: {
                    middleware: 'guest',
                    twofactor: true
                },
            },
            {
                path: '/forgot-password',
                name: 'forgot-password',
                component: () => import('@user-views/auth/ForgotPassword.vue'),
                meta: {
                    middleware: 'guest',
                    twofactor: true
                },
            },
            {
                path: '/password-reset/:token/:email',
                name: 'fpassword-reset',
                component: () => import('@user-views/auth/PasswordReset.vue'),
                meta: {
                    middleware: 'guest',
                    twofactor: true
                },
            },
        ]
    },
    {
        path: '/',
        component: () => import('@user-layouts/Main.vue'),
        children: [
            {
                path: '/settings',
                name: 'settings',
                component: () => import('@user-views/Settings.vue'),
                meta: {
                    middleware: 'auth',
                },
            },
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('@user-views/Dashboard.vue'),
                meta: {
                    middleware: 'auth',
                },
            },
            {
                path: '/payslips',
                name: 'payslips',
                component: () => import('@user-views/payslips/Paylips.vue'),
                meta: {
                    middleware: 'auth',
                },
            },
        ]
    },
]
